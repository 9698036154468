import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardMedia,
} from '@material-ui/core';
import moment from 'moment';

const styles = theme => ({
  file: {
    display: 'flex',
    margin: '0 48px 32px 0',
    height: 286,
  },
  card: {
    display: 'flex',
    height: 286,
    width: 215,
    borderRadius: 0,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 232,
    padding: '16px 0',
    marginLeft: 40,
  },
  name: {
    fontSize: 21,
    textTransform: 'uppercase',
  },
  info: {
    fontSize: 16,
    color: theme.palette.grey[500],
    minHeight: 90,
    overflow: 'auto',
  },
  button: {
    maxWidth: 113,
    color: theme.palette.grey[800],
    borderColor: theme.palette.grey[900],
    borderRadius: 0,
  },
});

class File extends Component {

  downloadFile = () => {
    const { file } = this.props;
    const byte = this.base64ToArrayBuffer(file.content);

    this.saveByteArray(file.name, byte, file.fileType);
  }

  base64ToArrayBuffer = base64Content => {
    const binaryString = window.atob(base64Content);
    const binaryLength = binaryString.length;
    const bytes = new Uint8Array(binaryLength);

    for (let i = 0; i < binaryLength; i++) {
      const ascii = binaryString.charCodeAt(i);

      bytes[i] = ascii;
    }

    return bytes;
  }

  saveByteArray(reportName, byte, fileType) {
    const blob = new Blob([byte], { type: fileType });

    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, this.props.file.name);

      return;
    }

    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = reportName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  };

  getFileTypeSvg = fileName => {
    let ext = '';

    if (fileName && fileName.length) {
      const lowerFileName = fileName.toLowerCase();

      ext = lowerFileName.split('.')[lowerFileName.split('.').length - 1];
    }

    return `/icons/${this.getSvgName(ext)}.svg`;
  }

  getSvgName = extension => {
    const fileTypes = {
      'xlsx': 'excel',
      'xls': 'excel',
      'pptx': 'ppt',
      'ppt': 'ppt',
      'pdf': 'pdf',
      'txt': 'txt',
      'jpg': 'image',
      'jpeg': 'image',
      'png': 'image',
      'gif': 'image',
      'tiff': 'image',
      'bmp': 'image',
      'doc': 'word',
      'docx': 'word',
    };

    return fileTypes[extension] || 'unknown';
  }

  render() {
    const {
      classes,
      file,
    } = this.props;

    const fileTypeSvg = this.getFileTypeSvg(file.name);

    return (
      <div className={classes.file}>
        <Card className={classes.card}>
          <CardMedia
            component="img"
            alt="Booking File"
            image={fileTypeSvg}
            title={file.name}
          />
        </Card>
        <div className={classes.details}>
          <div className={classes.name}>{file.name}</div>
          <div className={classes.info}>{`${!!file.comment ? file.comment : ''} Shared on ${moment(file.dateShared).calendar()}.`}</div>
          {/* <div className={classes.description}>{file.info}</div> TODO: need Cover UI to add this*/}
          <Button variant="outlined" classes={{ root: classes.button }} onClick={this.downloadFile}>
            DOWNLOAD
          </Button>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(File);
