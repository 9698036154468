import React, { Component } from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CompanyName from 'header/CompanyName';
import { DateTime } from 'luxon';

import {
  // ExpandMore as ExpandMoreIcon,
  AccessTime,
  Place,
  EventNote,
  PersonOutline,
} from '@material-ui/icons';
// import classNames from 'classnames';
import { ReduxState } from 'reducers/rootReducer';
import CompanyContext from 'company/CompanyContext';
import { Grid } from '@material-ui/core';

const styles = (theme: any) => createStyles({
  row: {
    display: 'flex',
    'flex-direction': 'row',
    margin: '3% 3% 0',
    background: theme.palette.common.white,
    border: '1px solid #F0F3F4',
  },
  colBorderBottom: {
    flex: '0.6',
    padding: '1em',
  },
  colBorderBottomLeft: {
    flex: '1',
    padding: '1em',
    marginLeft: 30,
    marginTop: 30,
  },
  nav: {
    fontSize: 27,
    fontWeight: 400,
    textAlign: 'left',
    paddingTop: 20,
    paddingBottom: 20,
    textTransform: "uppercase",
    color: theme.palette.light.purple1,
    marginBottom: 10,
  },
  navSeparator: {
    color: theme.palette.light.lightgrey,
    marginLeft: 15,
    marginRight: 15,
  },
  navButton: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  hr: {
    alignContent: 'center',
    alignItems: 'center',
    width: '80%',
  },
  iconContainer: {
    display: 'flex',
    fontSize: 20,
    justifyContent: 'space-evenly',
    marginTop: 15,
    marginBottom: 15,
    flexWrap: 'nowrap',
  },
  icon: {
    verticalAlign: 'middle',
    display: 'inline-block',
    color: theme.palette.light.red,
    height: 26,
    width: 20,
    marginBottom: 10,
  },

  iconLabel: {
    verticalAlign: 'middle',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: 14,
    fontWeight: 500,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    "@media (max-width: 425px)": {
      'flex-direction': 'column',
      'padding': '5',
    },
    "@media (max-width: 380px)": {
      'flex-direction': 'column',
      'padding': '5',
    },
  },
  extraPadding: {
    padding: '0px 24px',
    "@media (max-width: 420px)": {
      padding: 0,
    },
  },
  label: {
    color: theme.palette.blue.blue4,
    fontSize: 30,
    marginTop: '-5px',
    "@media (max-width: 420px)": {
      fontSize: 25,
      marginTop: 'auto',
    },
  },

  imgContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    "@media (max-width: 420px)": {
      width: '100%',
      paddingLeft: '24px',
    },
  },
  imageDefaultSize: {
    width: '300px',
    height: '205px',
  },
  red: {
    color: theme.palette.light.red,
  },
  bottomBorder: {
    height: 9,
    background: theme.palette.light.purple1,
    margin: '0 3%',
  },
});

type Props = WithStyles<typeof styles> & {
  booking: any;
  catereaseCP: string;
  getFinancialDetails: Function;
  confirmBooking: Function;
  imgSrc: string;
};

interface State {

}

const initialState: State = {

};

class EventHeaderDetail extends Component<Props, any> {
  state = initialState;

  static contextType = CompanyContext;

  componentDidMount() { }

  formatEndDate(startDateTime, endDateTime) {
    if (startDateTime.hasSame(endDateTime, 'day')) {
      return endDateTime.toFormat('h:mm a');
    }

    return endDateTime.toFormat('cccc, LLLL d, h:mm a');
  }

  render() {
    const { classes, booking, imgSrc } = this.props;
    const startDateTime = DateTime.fromISO(booking.startDateTime);
    const endDateTime = DateTime.fromISO(booking.endDateTime);

    return (
      <div>
        <div className={classes.row}>
          <div className={classes.colBorderBottomLeft}>
            <div style={{ fontWeight: 600 }}>
              <CompanyName fontSize={27} textAlign='left' />
            </div>
            <div className={classes.nav}>
              <span>HOME</span> <span className={classes.navSeparator}>|</span>
              <span> BOOKING</span> <span className={classes.navSeparator}>|</span>
              <span className={classes.red}> {booking.name}</span>
            </div>
            {/* <div>
              <hr className={classes.hr} />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', fontSize: 30 }}>
              {booking.name}
            </div> */}
            <Grid container={true} className={classes.iconContainer} >
              {booking && booking.contact && booking.contact.name &&
                <Grid item={true} xs={12} sm={12} md={6} lg={3}>
                  <div className={classes.navButton}>
                    <PersonOutline className={classes.icon} /><span className={classes.iconLabel}> {booking.contact.name}</span>
                  </div>
                </Grid>
              }
              <Grid item={true} xs={12} sm={12} md={6} lg={3}>
                <div className={classes.navButton}>
                  <EventNote className={classes.icon} /> <span className={classes.iconLabel}>{startDateTime.toFormat('MMMM dd yyyy')} </span>
                </div>
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6} lg={3}>
                <div className={classes.navButton}>
                  <AccessTime className={classes.icon} /> <span className={classes.iconLabel}>{startDateTime.toFormat('h:mma').toLowerCase()} - {endDateTime.toFormat('h:mma').toLowerCase()}</span>
                </div>
              </Grid>
              <Grid item={true} xs={12} sm={12} md={6} lg={3}>
                <div className={classes.navButton}>
                  <Place className={classes.icon} /><span className={classes.iconLabel}> {booking.name} </span>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.colBorderBottom}>
            <div className={classes.imgContent}>
              {!!imgSrc &&
                <img src={imgSrc} className={classes.imageDefaultSize} alt="logo" />
              }
            </div>
          </div>
        </div>
        <div className={classes.bottomBorder}></div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventHeaderDetail));
